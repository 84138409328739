import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'amphitheatre/dist/index.css';

export const onClientEntry = () => {
  const video = document.getElementById('intro-video');

  if (video) {
    const promise = video.play();

    if (promise) {
      promise
        .then((_) => {
          // Autoplay is fine
        })
        .catch((_) => {
          video.muted = true;
          video.play();
        });
    }
  }
};
